<template>
    <div>
        <Pane />
        <a-card class="container">
            <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }" :colon="false" :form="form">
                <a-row>
                    <a-col :lg="12" :md="12" :sm="24">
                        <a-form-item label="任务名称">
                            <a-input v-decorator="[
                                'name',
                                {
                                    initialValue: detail.name,
                                    rules: [
                                        { required: true, message: '请输入后选择！' },
                                    ],
                                },
                            ]" placeholder="请输入任务名称" style="width: 200px"></a-input>
                        </a-form-item>
                    </a-col>
                    <a-col :lg="12" :md="12" :sm="24">
                        <a-form-item label="任务时间">
                            <a-range-picker v-decorator="[
                                'date',
                                {
                                    initialValue: detail.date,
                                    rules: [
                                        { required: true, message: '请输入后选择！' },
                                    ],
                                },
                            ]" style="width: 200px" />
                        </a-form-item>
                    </a-col>
                    <a-col :lg="12" :md="12" :sm="24">
                        <a-form-item label="备注">
                            <a-input v-decorator="[
                                'remark',
                                {
                                    initialValue: detail.remark,
                                },
                            ]" placeholder="请输入备注" style="width: 200px"></a-input>
                        </a-form-item>
                    </a-col>
                    <a-col :lg="24" :md="24" :sm="24">
                        <a-form-item label="单位" :label-col="{ span: 3 }" :wrapper-col="{ span: 19 }">
                            <Company :list="companyList"/>
                        </a-form-item>
                    </a-col>
                    <a-col :lg="24" :md="24" :sm="24">
                        <a-form-item label="项目" :label-col="{ span: 3 }" :wrapper-col="{ span: 19 }">
                            <Project :list="projectList"/>
                        </a-form-item>
                    </a-col>

                </a-row>

                <div class="center">
                    <a-space>
                        <a-button @click="$close($route.path)">关闭</a-button>
                        <a-button type="primary" :loading="loading" @click="handleSubmit">保存</a-button>
                    </a-space>
                </div>
            </a-form>
        </a-card>
    </div>
</template>
  
<script>
import request from "@/api/request";
import Company from "./components/Company.vue";
import Project from "./components/Project.vue";
import organization from "@/mixins/organization";
import moment from "moment";
function save(data) {
    return request({
        url: "/office-service/quality/risk/task/modify",
        method: "post",
        data
    });
}
function getDetail(id) {
  return request({
    url: "/office-service/quality/risk/task/detail/" + id,
  });
}
export default {
    name: "technicalQualityScoreAdd",
    mixins: [organization],
    components: {
        Company,
        Project
    },

    data() {
        return {
            form: this.$form.createForm(this),
            companyList: [],
            projectList: [],
            loading: false,
            detail: {}
        };
    },
    mounted() {
        const { query } = this.$route;
        const { id } = query || {};
        getDetail(id).then(res => {
            this.detail = res;
            this.detail.date = [res.startDate, res.endDate]
            this.companyList = res.deptList;
            this.projectList = res.projectList;
        });
    },
    methods: {
        handleSubmit(e) {
            e.preventDefault();
            this.form.validateFields((err, values) => {
                if (!err) {
                    if (!this.companyList.length && !this.projectList.length) {
                        this.$message.error("请填写单位或项目");
                        return;
                    }

                    this.loading = true;

                    const { date } = values;
                    let startDate, endDate;
                    if (Array.isArray(date) && date.length === 2) {
                        startDate = moment(date[0]).format("YYYY-MM-DD");
                        endDate = moment(date[1]).format("YYYY-MM-DD");
                    }
                    const { query } = this.$route;
                    const { id } = query || {};
                    save({
                        id: id,
                        name: values.name,
                        remark: values.remark,
                        startDate: startDate,
                        endDate: endDate,
                        deptList: this.companyList,
                        projectList: this.projectList
                    })
                        .then(() => {
                            this.$close(this.$route.path);
                        })
                        .finally(() => {
                            this.loading = false;
                        });
                }
            });
        }
    },
};
</script>
  
  
<style lang="less" scoped>
.container {
    padding: 12px;
}

.center {
    margin-top: 80px;
    margin-bottom: 80px;
}
</style>