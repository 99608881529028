var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Pane'),_c('a-card',{staticClass:"container"},[_c('a-form',{attrs:{"label-col":{ span: 6 },"wrapper-col":{ span: 14 },"colon":false,"form":_vm.form}},[_c('a-row',[_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"任务名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'name',
                            {
                                initialValue: _vm.detail.name,
                                rules: [
                                    { required: true, message: '请输入后选择！' },
                                ],
                            },
                        ]),expression:"[\n                            'name',\n                            {\n                                initialValue: detail.name,\n                                rules: [\n                                    { required: true, message: '请输入后选择！' },\n                                ],\n                            },\n                        ]"}],staticStyle:{"width":"200px"},attrs:{"placeholder":"请输入任务名称"}})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"任务时间"}},[_c('a-range-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'date',
                            {
                                initialValue: _vm.detail.date,
                                rules: [
                                    { required: true, message: '请输入后选择！' },
                                ],
                            },
                        ]),expression:"[\n                            'date',\n                            {\n                                initialValue: detail.date,\n                                rules: [\n                                    { required: true, message: '请输入后选择！' },\n                                ],\n                            },\n                        ]"}],staticStyle:{"width":"200px"}})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"备注"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'remark',
                            {
                                initialValue: _vm.detail.remark,
                            },
                        ]),expression:"[\n                            'remark',\n                            {\n                                initialValue: detail.remark,\n                            },\n                        ]"}],staticStyle:{"width":"200px"},attrs:{"placeholder":"请输入备注"}})],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"单位","label-col":{ span: 3 },"wrapper-col":{ span: 19 }}},[_c('Company',{attrs:{"list":_vm.companyList}})],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"项目","label-col":{ span: 3 },"wrapper-col":{ span: 19 }}},[_c('Project',{attrs:{"list":_vm.projectList}})],1)],1)],1),_c('div',{staticClass:"center"},[_c('a-space',[_c('a-button',{on:{"click":function($event){return _vm.$close(_vm.$route.path)}}},[_vm._v("关闭")]),_c('a-button',{attrs:{"type":"primary","loading":_vm.loading},on:{"click":_vm.handleSubmit}},[_vm._v("保存")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }